@import '../../../../styles/scss/all';



.c-SettingPage-NewPreferencesPage{
    :global{
        .c-FormInput{
            height: 200px;
        }
    }
}