$primary-color: #fbc91b;

$primary-text-color: rgba(black, 0.8);
$dark-text: #333333;
$light-back: rgba(black, 0.6);
$alt-text-color: #5a7180;
$alt-dark-text-color: rgba(black, 0.5);
$disabled-text-color: rgba(black, 0.24);
$bright-white: #ffffff;
$alert-color: #ff4d4f;
$success-color: #007845;
$light-blue: #d9edff;
$alt-blue: #2d50b7;
$alt-grey: #aaaaaa;
$alt-white: #f2f2f2;
$dark-grey: #666666;
$pale-grey: #888888;
$grey-color: #c4c4c4;
$cta-dark-blue: #005ba1;

$gold-color: #e08700;
$gold-pale-color: #fbf3d5;

$title-font-weight: 800;
$normal-font-weight: 500;

$unit-title-color: #0f1b41;
$disabled-bg-color: #e0e0e0;

$form-btn-primary-bg-color: #04b9fa;
$form-el-bg-color: #f6f6f6;
$form-el-error-bg-color: #ffebef;
$danger-color: #ff4d4f;
$whatsapp-color: #25d366;
$live-color: #ff4f5a;

$std-border-radius: 12px;
$widget-border-radius: 4px;
$base-shadow: 0px 3px 28px rgba(0, 0, 0, 0.08);

$light-blue-bg: #f5faff;
$dark-blue-color: #036f96;

$divider-color: #e8e8e8;
$dark-color: #2c2c2c;
$white-text: #ffffff;

$disabled-button-text-color: rgba(0, 0, 0, 0.5);
$dark-yellow: #fbc91b1a;

$header-height: 64px;
$header-desktop-height: 0px;
$widget-spacing-desktop: 24px 32px;
$widget-spacing-mobile: 16px 16px;

$section-spacing-desktop: 48px 80px;
$section-spacing-mobile: 24px 16px;

$grey-700: #444444;

$nav-collapsed-width: 72px;
$nav-expanded-width: 240px;

$focus-view-bg-color: #222222;

$progress-bar-green: #12978f;
