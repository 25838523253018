@import '../../../styles/scss/all';

.c-Input {
  margin: 8px 0;

  &__container {
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      .c-EyeVisible {
        display: block;
      }

      &--left {
        left: 16px;
      }

      &--right {
        right: 16px;
      }
    }

    input {
      width: 100%;
      border: none;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 16px;
      background-color: $form-el-bg-color;

      @include fontRegular(16px, $dark-text);
    }

    &--w-left-icon input {
      padding-left: 56px;
    }

    &--w-right-icon input {
      padding-right: 48px;
    }
  }

  &--error {
    input {
      background-color: $form-el-error-bg-color;
    }
  }

  &__error {
    @include fontRegular(12px, $alert-color);
    margin: 8px 16px;
  }

  &--v2 {
    label {
        display: block;
        margin-bottom: 8px;
        @include fontRegularPoppins(14px, #666);
        // font-weight: 500;
      }
      
    &__container {
      input {
        height: 48px;
        // color: #333;
        padding: 16px;
        font-family: $font-poppins;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #e0e0e0;

        &:hover {
          border: 1px solid #666;
        }

        &:focus {
          border: 1px solid #666;
        }

        ::-webkit-input-placeholder {
          /* Edge */
          color: #aaa;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #aaa;
        }

        ::placeholder {
          color: #aaa;
        }
      }
      
      &--w-left-icon input {
        padding-left: 56px;
      }

      &--w-right-icon input {
        padding-right: 48px;
      }
    }
    &--error {
      input {
        border: 1px solid #db3952;
      }
    }

    & .c-Input__error {
      margin: 0;
      margin-top: 8px;

      @include fontRegularPoppins(14px, #db3952);
    }
  }
}
