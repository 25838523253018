@import '../../styles/scss/all.scss';

.c-LoginPage {
  width: 100vw;
  background-color: #f6f6f6;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &__container {
    width: 100%;
    height: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: row;

    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;

    @include for-desktop {
      width: 772px;
      height: unset;
      max-width: unset;
      overflow: scroll;
    }
  }

  &__media {
    max-width: 280px;
    display: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    @include for-desktop {
      display: block;
    }

    .c-Image {
      display: block;
    }
  }

  &__form {
    width: 100%;
    flex-grow: 1;

    padding: 56px 24px 24px 24px;
    margin: auto;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    @include for-desktop {
      padding-top: 32px;
    }
  }
}
