@import '../../../../styles/scss/all';

$redeem-gray-color: rgba(51, 51, 51, 0.5);

$black-color: #0000;
.c-SettingPage-RedeemBox {
  @include column();
  position: relative;

  @include for-desktop {
    // width: 193px;
    padding: 16px 0px;
  }

  &__credit-card {
    // border: 1px solid $disabled-bg-color;
    border-radius: $widget-border-radius;
    margin-bottom: 8px;

    &__title {
      // @include fontRegularPoppins(14px, $black-color);
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 500;
      // text-align: center;
      margin: 0;
      padding: 8px;
      // border-bottom: 1px solid $disabled-bg-color;
    }

    &__amount {
      @include fontTitlePoppins(16px, $primary-text-color);
      line-height: 22px;
      margin: 0;
      padding-left: 12px;
      // text-align: center;
    }
  }

  &__redeem-note {
    @include fontRegularPoppins(12px, $redeem-gray-color);
    line-height: 17px;
    font-weight: 400;
    // text-align: center;
    padding-left: 12px;

    .c-Text--bolder {
      font-weight: 700;
    }
  }

  &__no-credit-text {
    @include fontRegularPoppins(14px, $redeem-gray-color);
    line-height: 20px;
    // text-align: center;
    padding:  8px;
    // width: 142px;
    // margin: 0 auto;
  }

  &__cta {
    .c-Button {
      width: 100%;
      margin-bottom: 8px;
      padding: 8px;
      font-size: 12px;
      line-height: 17px;
    }
  }

  &__cta--disabled {
    .c-Button {
      background-color: transparent;
      color: $alt-grey;
      border-color: $alt-grey;
    }
  }
}
