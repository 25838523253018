@import '../../styles/scss/all.scss';

.c-Checkbox {
  @include fontRegular(16px, $dark-text);
  line-height: 24px;
  display: block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 24px;
  height: 18px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #20cb95;
    border-radius: 4px;

    &::after {
      content: ' ';
      position: absolute;
      display: none;
    }

    &--checked {
      background-color: #20cb95;

      &::after {
        content: ' ';
        position: absolute;
        display: block;
        left: 5px;
        top: -1px;
        width: 4px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
