@import '../../styles/scss/all.scss';

.c-Text {
  &--highlighted {
    color: $primary-color;
  }

  &--bolder {
    font-weight: 700;
  }

  &--newline {
    display: block;
  }

  &--anchor {
    font-weight: 700;
    color: $primary-color;
    cursor: pointer;
    text-decoration: underline;
  }

  &--newline {
    display: block;
  }

  &--striked {
    text-decoration: line-through;
  }

  &--underline {
    text-decoration: underline;
  }

  &--link {
    text-decoration: underline;
    font-weight: 700;
    color: inherit;
  }

  &--new-paragraph {
    display: block;
    margin-top: 18px;

    @include for-desktop {
      margin-top: 24px;
    }
  }
}
