@import '../../styles/scss/all.scss';

.c-SetUserBasicDataModal {
  &__modal-container {
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 48px);
    padding: 24px 16px;
    @media only screen and (min-width: 768px) {
      border-radius: 28px;
      height: fit-content;
      padding: 24px;
      max-width: 568px;
      max-height: calc(100vh - 80px);
      box-shadow: 0px 2px 8px rgba(27, 27, 24, 0.1),
        0px 4px 6px rgba(27, 27, 24, 0.04), 0px 8px 16px rgba(27, 27, 24, 0.04),
        0px 12px 24px 2px rgba(27, 27, 24, 0.04),
        0px 20px 48px 8px rgba(27, 27, 24, 0.1);
    }
    overflow: auto;
    z-index: 1001;
  }

  &__modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    .c-Divider {
      margin-left: 0;
    }
  }

  .c-Input {
    label {
      @include fontRegular(12px, $disabled-button-text-color);
    }
  }

  &__title {
    margin: 0;
    /* Heading/md/semiBold */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: -0.019em;

    /* Text Colour/Light Surface/Primary */

    color: #191c1e;
  }

  &__modal-title-banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__logo {
    justify-self: center;
    margin-bottom: 24px;
  }

  &__complete-details {
    @include fontRegular(12px, $dark-text);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 24px;
  }

  &__desc {
    @include fontRegular(12px, $disabled-button-text-color);
    margin-bottom: 32px;
  }

  &__email-container__label,
  &__label {
    @include fontRegular(12px, $alt-dark-text-color);
    margin: 0 0 8px 0;
  }

  &__email-container {
    &__value {
      @include fontRegular(16px, $dark-text);
      font-weight: 700;
      margin: 0 0 16px 0;
    }
  }

  &__single-line {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    .c-Input {
      flex: 1;
    }

    &--name {
      .c-FormField:first-child {
        flex-basis: 60%;
      }
    }

    &--phone {
      .c-FormField:first-child {
        flex-basis: 40%;
      }
    }
  }

  &__btn-container {
    margin-top: 24px;
  }

  &__err-msg-container {
    @include fontRegular(16px, $alert-color);

    margin-top: 16px;
  }

  .c-FormField {
    margin-top: 24px;
  }
}
