@import '../../styles/scss/all.scss';

$fb-btn-color: #3b579d;
$google-btn-color: #4285f4;

.c-SocialLogins {
  &__social-btn-wrapper {
    .c-Button {
      position: relative;
      // @include fontRegularPoppins(14px, #2c2c2c);
      font-family: "Google Sans", arial, sans-serif;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #dadce0;
      border-radius: 4px;
      background-color: #fff;
      color: #3c4043;
      max-height: 38px;
      &:hover {
        background-color: #f2f2f2;
        border-color: #d2e3fc;
        background-color: rgba(66,133,244,0.04);
      }
    }

    &--google {
      margin-bottom: 12px;

      .c-Button {
        &::before {
          position: absolute;
          left: 16px;
          content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/google_logo.svg');
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }

    &--facebook {
      .c-Button {
        &::before {
          position: absolute;
          left: 16px;
          content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/facebook_logo.svg');
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }

    &--apple {
      margin-top: 12px;
      .c-Button {
        &::before {
          position: absolute;
          left: 16px;
          content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/apple-24.png');
          max-width: 24px;
          max-height: 24px;
          margin-right: 12px;
        }
      }
    }

    &--discord {
      margin-top: 12px;
      .c-Button {
        &::before {
          position: absolute;
          left: 16px;
          content: url('https://d2oi1rqwb0pj00.cloudfront.net/icons/discord.svg');
          max-width: 24px;
          max-height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
}
