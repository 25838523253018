@import '../../../../styles/scss/all';

.c-NewProfilePage {
  &__social_media_Newprofilepage {
    margin-left: 8px;
    @include for-desktop {
      margin-left: 10px;
    }
    
    
  }
  
  &____social_media_Newprofilepage_socialIcons{
    margin-left: 25px;
  }
  :global {
    .c-Select__container select {
      padding: 8px;
    }
  }
}
.modelClass {
  max-height: 700px;
  overflow-y: scroll;
}

.c-NewProfilePagediscard-changes {
  margin-top: 20px;
  justify-content: end;
}

.c-NewProfilePage__socialmedia_links .c-FormInput__input-wrapper {
  @include for-desktop {
    width: 400px;
  }

  @include for-mobile {
    width: 300px;
  }
  // width: 400px;
}
// .outer-div-profile-page {
//   width: 100%;
// }

.social_media_Newprofilepage {
  margin-left: 50px;
}
