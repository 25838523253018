@import '../../../styles/scss/all.scss';

.c-LoginForm {
  .c-Image {
    width: 32px;
    height: 32px;

    display: block;
    margin-bottom: 32px;
  }

  &__or {
    @include fontRegular(12px, $disabled-button-text-color);
    margin: 16px 0;
    text-align: center;
  }

  &__form {
    width: 100%;
    .c-Button {
      margin-top: 16px;
      padding: 15px;
    }

    .c-Input {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }

  &__error-message {
    @include fontRegularPoppins(14px, #db3952);
  }

  &__or {
    position: relative;
    margin: 16px 0;
    text-align: center;
    height: 12px;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #e0e0e0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    &__text {
      @include fontRegularPoppins(12px, #aaa);
      padding: 0 12px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &__form__link {
    text-align: right;
    a {
      @include fontRegularPoppins(14px, #666);
      font-weight: 500;
      text-align: right;
      text-decoration: underline;
    }
  }
}
