@import '../../styles/scss/all.scss';

.c-Button {
  border-radius: $widget-border-radius;
  cursor: pointer;
  border: none;
  font-family: $font-poppins;
  font-size: 16px;
  line-height: 16px;
  font-weight: 800;
  padding: 13px 24px;

  span {
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    font-family: $font-poppins;
  }

  &--primary {
    color: $dark-text;
    background-color: $primary-color;
  }

  &--secondary {
    color: $bright-white;
    background-color: $dark-text;
  }

  &--fluid {
    width: 100%;
  }

  &--shadow {
    box-shadow: $base-shadow;
  }

  &--narrow {
    padding: 12px;
  }

  &--disabled {
    color: $alt-dark-text-color;
    cursor: not-allowed;
    background: #e0e0e0;
  }

  &--form-primary {
    background-color: $form-btn-primary-bg-color;
    color: $bright-white;
  }

  &--standard-inverse {
    background-color: white;
    color: $dark-color;
    border: 1px solid $dark-color;

    &:hover {
      background: darken($color: white, $amount: 5);
    }
  }

  &--inverse {
    background-color: transparent;
    border: 1px solid $alt-grey;
    color: $alt-white;
  }

  &--dark-inverse {
    background-color: transparent;
    border: 1px solid $dark-grey;
    color: $focus-view-bg-color;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &--new-version {
    font-variant: small-caps;
    border-radius: 4px;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;

    span {
      font-family: $font-poppins;
    }
  }

  &--table-btn {
    @include fontRegularPoppins(14px, $dark-color);

    font-weight: normal;
    padding: 12px 16px;
    background: white;
    border-radius: 4px;
    border: 1px solid $dark-color;
  }
}
