.Container {
  width: 100%;
  height: 100vh;
  background-color: #2c2d2e;
  text-align: center;
  color: white;
  padding-top: 16px;
}

.Yellow {
  color: #fbc91b;
}

.Bold {
  font-weight: bold;
}

.ErrorType {
  font-size: 80px;
  font-family: "FatFrank", sans-serif;
  padding: 0;
  box-sizing: border-box;
}

.PageNotFound {
  font-size: 32px;
  margin: 0 0 48px 0;
  padding: 0;
  font-weight: bold;
}

.Description {
  width: 400px;
  margin: auto;
  font-size: 24px;
}

.Button {
  background-color: #fbc91b;
  color: black;
  font-weight: bold;
  margin: 48px 0 48px 0;
  padding: 16px 32px;
  border-radius: 5px;
}
.Button :hover {
  cursor: pointer;
  /* transform: translateX(0.5em); */
  transition: 0.3s ease-in-out;
  background-color: #feeeb8;
}

@media (max-width: 480px) {
  .ErrorType {
    font-size: 64px;
  }

  .Description {
    width: 300px;
  }
}
