@import '../../../styles/scss/all.scss';

@keyframes modal-slide-up {
  from {
    top: calc(50% + 30px);
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.c-Modal {
  display: none;

  &--open {
    display: block;
    z-index: 1000;
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-text;
    opacity: 0.5;
    z-index: 1000;
  }

  &__container {
    position: fixed;
    width: 90%;
    max-width: $tablet-width;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    background-color: white;
    border-radius: 28px;
    box-sizing: border-box;
    animation: modal-slide-up 0.3s ease-out;
    overflow-y: auto;
    max-height: 80vh;

    // box-shadow-02
    box-shadow: 0px 12px 24px 4px rgba(27, 27, 24, 0.04), 0px 10px 20px 2px rgba(27, 27, 24, 0.02), 0px 8px 16px 0px rgba(27, 27, 24, 0.04), 0px 4px 6px 0px rgba(27, 27, 24, 0.04), 0px 1px 4px 0px rgba(27, 27, 24, 0.10);


    @include for-desktop {
      width: initial;
    }
  }

  &__close-modal {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 103;
  }
}
