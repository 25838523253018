@import './styles/scss/all.scss';

.c-App {
  .mobile-only {
    display: block;

    @include for-desktop {
      display: none;
    }
  }

  .desktop-only {
    display: none;

    @include for-desktop {
      display: block;
    }
  }
}

.userback-button-container {
  display: flex !important;
}

.userback-button {
  display: none !important;
}

.nested-list-item {
  list-style-type: none !important;
}
.nested-list-item:before,
.nested-list-item:after {
  display: none !important;
}

.hide-scrollbars {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbars::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
