@import '../../../styles/scss/all.scss';

.c-FloatingLabelInput {
  width: 100%;
  height: 100%;
  /**
    * Add a transition to the label and input.
    * I'm not even sure that touch-action: manipulation works on
    * inputs, but hey, it's new and cool and could remove the 
    * pesky delay.
    */
  label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  /**
    * Translate down and scale the label up to cover the placeholder,
    * when following an input (with placeholder-shown support).
    * Also make sure the label is only on one row, at max 2/3rds of the
    * field—to make sure it scales properly and doesn't wrap.
    */
  input:placeholder-shown + label {
    cursor: text;
    max-width: calc(100% - 16px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(8px, -40px);
    font-size: 16px;
    line-height: 24px;
  }

  /**
    * By default, the placeholder should be transparent. Also, it should
    * inherit the transition.
    */
  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  /**
    * Show the placeholder when the input is focused.
    */
  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  /**
    * When the element is focused, remove the label transform.
    * Also, do this when the placeholder is _not_ shown, i.e. when
    * there's something in the input at all.
    */
  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(8px, -49px);
    /* Label/sm/regular */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Text Colour/Light Surface/Secondary */

    color: #75777a;
    cursor: pointer;
  }
}
