$tablet-width: 768px;
$lg-tablet-width: 992px;
$desktop-width: 1200px;
$max-mobile-width: 425px;

@mixin for-mobile-landscape-only {
  @media only screen and (min-width: 320px) and (max-height: 640px) {
    @content;
  }
}

@mixin for-medium-screen {
  @media only screen and (max-width: $desktop-width - 1) and (min-width: 640px) {
    @content;
  }
}

@mixin for-mobile {
  @media only screen and (max-width: $max-mobile-width) {
    @content;
  }
}

@mixin for-sm {
  @media only screen and (min-width: $max-mobile-width) and (max-width: $tablet-width) {
    @content;
  }
}

@mixin for-tablet-only {
  @media only screen and (min-width: $tablet-width) and (max-width: $lg-tablet-width) {
    @content;
  }
}

@mixin for-tablet-up {
  @media only screen and (min-width: $tablet-width) {
    @content;
  }
}

@mixin for-lg-tablet-up {
  @media only screen and (min-width: $lg-tablet-width) {
    @content;
  }
}

@mixin for-desktop {
  @media only screen and (min-width: $desktop-width) {
    @content;
  }
}

@mixin fontTitle($f-size, $color) {
  font-size: $f-size;
  font-family: $font-title;
  color: $color;
  font-weight: 800;
}

@mixin fontHeader($f-size, $color) {
  font-size: $f-size;
  font-family: $font-header;
  color: $color;
  font-weight: 800;
}

@mixin fontRegular($f-size, $color) {
  font-size: $f-size;
  font-family: $font-regular;
  color: $color;
}

@mixin fontRegularPoppins($f-size, $color) {
  font-size: $f-size;
  font-family: $font-poppins;
  color: $color;
}

@mixin fontTitlePoppins($f-size, $color) {
  font-size: $f-size;
  font-family: $font-poppins;
  color: $color;
  font-weight: bold;
}

@mixin applyMaxWidth() {
  margin-right: auto;
  margin-left: auto;
  max-width: $max-mobile-width;

  @include for-desktop {
    max-width: 1440px;
  }
}

@mixin applyPageContainerTopMargin() {
  margin-top: $header-height;

  @include for-tablet-up {
    margin-top: $header-desktop-height;
  }
}

@mixin applyPageContainerPadding() {
  padding-top: $header-height;

  @include for-desktop {
    padding-top: $header-desktop-height;
  }
}

@mixin applyWidgetPadding() {
  padding: $widget-spacing-mobile;

  @include for-desktop {
    padding: $widget-spacing-desktop;
  }
}

@mixin applyPageSectionPadding() {
  padding: $section-spacing-mobile;

  @include for-desktop {
    padding: $section-spacing-desktop;
  }
}

@mixin applyWidgetMargin {
  margin: 16px 0;

  @include for-desktop {
    margin: 24px 0;
  }
}

@mixin flexCenter() {
  justify-content: center;
  align-items: center;
}

@mixin row() {
  display: flex;
  flex-direction: row;
}

@mixin rowCenter() {
  @include row();
  @include flexCenter();
}

@mixin column() {
  display: flex;
  flex-direction: column;
}

@mixin columnCenter() {
  @include column();
  @include flexCenter();
}

@mixin hideUglyScrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: thin;
    display: none;
  }
}

@mixin text-overflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

@mixin disable-selection {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@mixin for-focus-page-light-theme {
  .c-CohortFocusViewPage-theme-light & {
    @content;
  }
}

@mixin for-focus-page-desktop-light-theme {
  .c-CohortFocusViewPage-theme-light & {
    @include for-desktop {
      @content;
    }
  }
}
