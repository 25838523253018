@import '../../styles/scss/all';

.c-ResetPasswordPage {
  width: 100vw;
  height: 100vh;
  background-color: #f6f6f6;

  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    height: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: row;

    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;

    @include for-desktop {
      height: unset;
    }
  }

  .c-Image {
    width: 48px;
    height: 48px;

    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .c-Button {
    margin-top: 16px;
    padding: 16px;
  }
  .c-Input {
    &:first-child {
      margin-bottom: 16px;
    }
  }

  &__title {
    text-align: center;
    @include fontRegularPoppins(16px, #333);
    font-weight: 500;
    margin-bottom: 24px;
  }

  &__rules {
    background-color: #f2f2f2;
    border-radius: 4px;

    padding: 16px;
    margin-bottom: 16px;
    @include fontRegularPoppins(12px, #333);

    &__list {
      margin-left: 20px;

      &__item {
        list-style: disc;
      }
    }
  }

  &__form {
    width: 100%;
    padding: 48px 24px;
    margin: auto;
  }

  &__error-message {
    @include fontRegularPoppins(14px, #db3952);
    margin-top: 16px;
  }
}
