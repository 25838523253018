@import '../../../../styles/scss/all';

.c-ResetPasswordContainer-PasswordInput {
  width: 250px;
  margin: 0 auto;

  &__legend {
    @include fontRegular(12px, $alt-dark-text-color);
    line-height: 1.4;
  }
}
