@import '../../styles/scss/all.scss';

.c-ForgetPasswordPage {
  width: 100vw;
  height: 100vh;
  background-color: #f6f6f6;

  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    height: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: row;

    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;

    @include for-desktop {
      height: unset;
    }
  }
}
