@import '../../styles/scss/all.scss';

.c-PasswordExpiredModal {
  &__container {
    width: 90%;
    max-width: 328px;
    margin: 0 auto;
    @include for-desktop {
      max-width: 500px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px;
  }

  &__title {
    @include fontTitlePoppins(20px, $dark-text);
    font-weight: 500;
    text-align: start;
    margin-bottom: 16px;

    @include for-desktop {
      font-size: 20px;
    }
  }

  &__text {
    text-align: start;
    margin-bottom: 24px;
    @include fontRegularPoppins(16px, $dark-grey);
    line-height: 24px;
  }

  &__cta {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: '24px';
    justify-content: end;
  }
}
.c-Modal__container {
  padding: 24px;
  border-radius: 8px;
}

.c-Modal__close-modal {
  top: 26px;
  right: 24px;
}

.c-Button {
  font-size: 14px;
  font-weight: 600;
}
