@import '../../styles/scss/all';

.c-ResetPasswordContainer {
  &--change-flow {
    .sections {
      margin-left: 0;
      margin-right: 0;
    }

    @include for-desktop {
      margin-left: 2em;
      margin-right: 2em;
    }

    &__title {
      @include fontTitle(22px, $dark-text);

      &__wrapper {
        @include for-desktop {
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    &__subtext {
      @include fontRegular(12px, $alt-dark-text-color);
      margin-top: 8px;

      @include for-desktop {
        font-size: 16px;
      }
    }

    .c-Button {
      margin-top: 16px;
    }

    .c-ResetPasswordContainer-PasswordInput {
      input {
        @include fontRegular(16px, $dark-text);
        border: none;
        background: #f6f6f6;
        border-radius: 8px;
        margin-bottom: 8px;
        margin-top: 8px;

        &::placeholder {
          @include fontRegular(16px, $alt-dark-text-color);
        }
      }
    }
  }
}
