.showToast {
  animation: toastFadeIn 200ms ease-in-out forwards;
}

.hideToast {
  animation: toastFadeOut 200ms ease-in-out forwards;
}

@keyframes toastFadeIn {
  0% {
    opacity: 0;
    transform: translateY(32px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes toastFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
