* {
  box-sizing: border-box;
}

html,
body {
  color: #000;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: auto;
}

ul,
p {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.h-full-dvh {
  height: 100vh; /* fallback for browsers that don't support 100dvh */
  height: 100dvh; /* fallback for browsers that don't support dvh */
}

@media only screen and (max-width: 1550px) {
  html {
    font-size: 12px;
  }
}