.success-box {
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  background: #e2f4ec;
  color: #2c2c2c;
  z-index: 100;
}

.success-box.absolute {
  position: absolute;
}

.success-box .top {
  top: 100px;
}

.success-box.right {
  right: 8px;
}
