@import '../../../styles/scss/all';
.c-AuthenticationWrapper {
  position: relative;

  .c-Card {
    width: 100%;
    height: auto;
    padding: 80px 0;
    box-shadow: none;

    @include for-desktop {
      width: 600px;
      position: fixed;
      top: 50%;
      transform: translate(0, -50%);
      right: 60px;
      vertical-align: center;
      box-shadow: 0 3px 28px rgba(0, 0, 0, 0.08);
      z-index: 10;
      background: $bright-white;
    }
  }

  .c-Button {
    width: 328px;

    @include for-desktop {
      width: 348px;
    }
  }

  &__background__wrapper {
    display: none;

    @include for-desktop {
      display: initial;
      position: fixed;
      top: 52%;
      transform: translate(0, -50%);
      left: 0;

      .c-Image {
        height: 772px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__logo__wrapper {
    width: 326px;
    height: 26px;
    margin: 32px auto 8px auto;
    text-align: center;

    .c-Image {
      width: 100%;
      height: 100%;
    }
  }

  &__subtext {
    @include fontTitle(12px, $dark-text);
    text-align: center;
    margin: 0 0 63px 0;

    @include for-desktop {
      font-size: 14px;
    }
  }

  &__form__wrapper {
    margin: 0 auto;
    text-align: center;
  }

  .c-Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    input {
      @include fontRegular(16px, $alt-dark-text-color);
      padding: 16px 16px;
      width: 328px;
      background: #f6f6f6;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 40px;
      border-radius: $std-border-radius;

      @include for-desktop {
        width: 348px;
      }
    }

    &__forget-password {
      @include fontRegular(12px, $dark-text);
      margin: 12px;
      text-decoration: underline;
    }

    &__link {
      @include fontTitle(16px, $dark-text);
      margin-top: 24px;
      text-decoration: underline;
    }

    &__error-message {
      margin: 0 auto;
      padding-top: 1em;
      text-align: center;
      font-size: 0.8em;
      color: darkred;
    }

    &__success-message {
      margin: 0 auto;
      padding-top: 1em;
      text-align: center;
      font-size: 1em;
      color: #0065f2;
    }
  }
}
