@import '../../../styles/scss/all';

.c-TriggerResetPasswordForm {
  width: 100%;
  padding: 48px 24px;
  margin: auto;

  .c-Image {
    width: 48px;
    height: 48px;

    display: block;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .c-Input {
    margin-bottom: 24px;
  }

  .c-Button {
    padding: 16px;
  }

  &__title {
    text-align: center;
    @include fontRegularPoppins(16px, #333);
    font-weight: 500;
    margin-bottom: 24px;
  }

  &__desc {
    @include fontRegularPoppins(14px, #666);
    margin-bottom: 24px;
  }

  &__error-message {
    @include fontRegularPoppins(14px, #db3952);
    margin-bottom: 8px;
  }

  &__success-card {
    width: fit-content;
    position: fixed;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;

    padding: 16px;
    background-color: #def7f5;

    display: flex;
    align-items: center;
    justify-content: center;

    @include for-desktop {
      bottom: 48px;
    }

    &__text {
      @include fontRegularPoppins(16px, #12978f);
      font-weight: 500;
      margin-left: 14px;
      margin-right: 24px;
    }

    &__check {
      width: 20px;
      height: 20px;
      background-color: #12978f;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      .c-CheckIcon {
        path {
          stroke: #fff;
        }
      }
    }

    .c-CloseIcon {
      path {
        fill: #12978f;
      }
    }
  }
}
