@import '../../styles/scss/all.scss';

.c-Divider {
  height: 10px;
  width: 100px;
  border-radius: 10px;
  background-color: $primary-color;
  margin: 12px 0;

  @include for-desktop {
    margin: 24px auto;
  }

  &--narrow {
    width: 32px;
    height: 6px;

    @include for-desktop {
      width: 40px;
      height: 8px;
    }
  }
}
