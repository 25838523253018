@import '../../styles/scss/all.scss';

.c-Modal {
  display: none;

  &--open {
    display: block;
    z-index: 1000;
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-text;
    opacity: 0.5;
    z-index: 999;
  }

  &__container {
    position: fixed;
    width: 90%;
    max-width: $tablet-width;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    background-color: white;
    border-radius: 4px;
    z-index: 1000;

    @include for-desktop {
      width: initial;
    }
  }

  &__close-modal {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
  }
}
