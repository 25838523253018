@import '../../../../styles/scss/all';



.c-PasswordSettingWidget {
  .c-ResetPasswordContainer {
    @include for-desktop {
      // padding-left: 24px;
    }

    &-PasswordInput {
      margin: initial;
      display: flex;
      width: auto;
      @include column();

      @include for-desktop {
        @include column();
        // align-items: center;
        margin-bottom: 8px;

        &:nth-child(2) {
          align-items: flex-start;

          .c-ResetPasswordContainer-PasswordInput__label {
            padding-top: 12px;
          }
        }
      }

      &__label {
        @include fontRegularPoppins(14px, #888888);
        flex-shrink: 0;

        @include for-desktop {
          // width: 30%;
        }
      }

      &__legend {
        @include fontRegularPoppins(12px, #aaaaaa);
        line-height: 1.4;
        margin-bottom: 8px;

        @include for-desktop {
          max-width: 265px;
          margin-top: -4px;
        }
      }

    }

    .input-wrapper {
      align-items: initial;
    }

    .c-Input {
      margin: 0;

      &__container {
        ::-webkit-input-placeholder {
          color: transparent;
        }

        :-moz-placeholder {
          /* Firefox 18- */
          color: transparent;
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          color: transparent;
        }

        :-ms-input-placeholder {
          color: transparent;
        }

        &__icon {
          transform: translate(0, -70%);
        }
      }

      input {
        border: 1px solid #aaaaaa;
        box-sizing: border-box;
        border-radius: 4px;
        background: transparent;
        @include fontRegularPoppins(16px, $dark-text);
        margin-top: 8px;
        padding: 8px;

        @include for-desktop {
          // width: 321px;
          margin-top: 0;
        }

        ::placeholder {
          display: none;
        }
      }
    }

    &-ResetPasswordForm {
      &__cta {
        &__container {
          display: flex;
          @include column();

          @include for-desktop {
            @include row();
          }
        }

        &__left {
          flex-shrink: 0;

          @include for-desktop {
            width: 20%;
          }
        }

        &__wrapper {
          display: flex;
          align-items: center;
          @include column();

          @include for-desktop {
            @include row();
          }
        }

        &__link {
          @include fontRegularPoppins(14px, #888888);
          cursor: pointer;

          @include for-desktop {
            margin-left: 16px;
          }
        }
      }
    }
  }

  &__password-rules-main-container{
    @include for-desktop {
      background:#f2f2f2;
      margin-block:16px
    }
  }

  .error-message {
    @include fontRegularPoppins(12px, $alert-color);
    text-align: center;
    margin-top: 8px;

    @include for-desktop {
      text-align: left;
      font-size: 14px;
    }
  }
}
