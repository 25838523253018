@import '../../../styles/scss/all.scss';

.c-Select {
  @include fontRegular(16px, $dark-text);

  &__container {
    select {
      @include fontRegular(16px, $dark-text);

      border: none;
      border-radius: 8px;
      padding: 16px;
      background: url('https://d2oi1rqwb0pj00.cloudfront.net/na-website/svg/chevron-down.svg')
        no-repeat 95% $form-el-bg-color;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;

      &::-ms-expand {
        display: none;
      }

      &:invalid {
        color: $alt-text-color;
      }
    }
    &--no-chevron {
      select {
        background: none;
        padding: 10px;
      }
    }
  }

  &--error {
    select {
      background-color: $form-el-error-bg-color;
    }
  }

  &--show-placeholder {
    select {
      color: $alt-text-color;
    }
  }

  &__error {
    @include fontRegular(16px, $alert-color);
    margin: 8px 0;
  }

  &--v2 {
    label {
      display: block;
      margin-bottom: 8px;
      @include fontRegularPoppins(14px, #666);
      // font-weight: 500;
    }

    select {
      height: 48px;
      color: #333;
      padding: 0 8px;
      font-family: $font-poppins;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid #e0e0e0;

      &:hover {
        border: 1px solid #666;
      }

      &:focus {
        border: 1px solid #666;
      }

      ::-webkit-input-placeholder {
        /* Edge */
        color: #aaa;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #aaa;
      }

      ::placeholder {
        color: #aaa;
      }
    }

    &--error {
      input {
        border: 1px solid #db3952;
      }
    }

    & .c-Input__error {
      margin: 0;
      margin-top: 8px;

      @include fontRegularPoppins(14px, #db3952);
    }
  }
}
