@import '../../../../styles//scss//all.scss';

.c-EditProfilePage {
  max-width: 680px;
  font-family: $font-poppins;

  .c-Input {
    margin: 0;

    label {
      display: block;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    input {
      font-family: $font-poppins;
      height: 48px;
      border: 1px solid #dbdbd7;
      background: none;
    }

    &--disabled {
      input {
        background-color: #f3f3f2;
        color: rgba(27, 27, 24, 0.5);
        border: 1px solid #f3f3f2;
      }
    }
  }

  .c-Select {
    label {
      font-family: $font-poppins;
      display: block;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    select {
      color: #1b1b19;
      height: 48px;
      border: 1px solid #dbdbd7;
      border-radius: 12px;
      font-family: $font-poppins;
    }
  }
}
