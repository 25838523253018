@import '../../../../styles/scss/all';

.c-ProfileImageSetting {
  &__avatar {
    &__upload {
      position: relative;
      width: 64px;
      height: 64px;
      @include for-tablet-up {
        width: 100px;
        height: 100px;
      }
      // margin: 0 0 20px 0;
    }

    &__edit {
      position: absolute;
      z-index: 1;
      right: -10px;
      bottom: 0px;

      .c-CameraIcon {
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        padding: 4px;
        border-radius: 50%;
        background-color: $form-el-bg-color;

        path {
          fill: $dark-grey;
        }
      }

      input {
        display: none;
      }

      input + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
      }

      input + label:hover {
        background: #f1f1f1;
        border-color: #d6d6d6;
      }
    }

    &__preview {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &__container {
        width: 64px;
        height: 64px;

        @include for-tablet-up {
          width: 100px;
          height: 100px;
        }
        position: relative;
        border-radius: 100%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }

    &__upload {
      &__container {
        display: flex;
        flex-direction: column;
        max-width: 200px;

        // @include for-desktop {
        //   max-width: 200px;
        // }
      }

      &__label {
        @include fontRegularPoppins(14px, $dark-grey);

        @include for-desktop {
          font-size: 14px;
          margin-bottom: 0;
        }
      }

      &__options {
        display: flex;
        @include row();
        margin-top: 16px;
        margin-bottom: 16px;

        @include for-desktop {
          @include column();
        }

        .c-Button {
          margin: auto auto 8px auto;
          width: 120px;

          &--form-primary {
            background: $primary-color;
            color: $dark-text;
          }

          &--secondary {
            background: #e0e0e0;
            color: $dark-text;
          }
        }
      }
    }
  }

  canvas {
    margin: 12px auto 0 auto;
    width: 150px !important;
    height: 150px !important;

    // @include for-desktop {
    //   width: 150px !important;
    //   height: 150px !important;
    // }
  }
}
