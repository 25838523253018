@import "../../../../styles/scss/all";

.c-ResetPasswordContainer {
  &__main-page-form {
    text-align: center;
    margin: 0 auto;
  }

  &__inner-main-form-wrapper {
    width: 100%;
  }

  .c-Button {
    margin: 0 auto;
  }

  .success-message {
    @include fontRegularPoppins(12px, $success-color);
  }

  .error-message {
    @include fontRegularPoppins(12px, $alert-color);
  }
}
